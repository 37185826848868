//Gradient colors
$blue-gradient: linear-gradient(71deg, #003fa2, #0062ff);
$grey-gradient: linear-gradient(71deg, #eceef1, #fefeff);
$red-gradient: linear-gradient(71deg, #b90000, #fc0000);
$green-gradient: linear-gradient(71deg, #65a704, #73bb08);

//New gradient
$blue-gradient2: linear-gradient(-63deg, #0057e4 0%, #0043b0 65%, #003c9c 100%);
$blue-text-gradient: linear-gradient(-63deg, #003a98 0%, #0043b0 51%, #003c9c 100%);
$white-gradient: linear-gradient(146deg, #f9f9f9 0%, #f4f4f4 53%, #f5f5f5 97%);

//Shadow
//$shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
//$shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$shadow2: 0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16);

//Colors (card heading, body, td table)
$primary-dark-color:#1F1F1F;

//Colors (card footer, th columns, icons)

//Link color (yesterday, by day....)
$link-color: #0062ff;

//Theme colors
$dark-theme-color: #343a40;
$dark-theme-second-color: #272b2f;
$dark-theme-blue-color: #4885ed;
$light-theme-color: #f3f4f6;

$blue-color: #0856cd;
$blue-light-color: #f4f8ff;