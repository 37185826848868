/* You can add global styles to this file, and also import other style files */
@import './colors';
@import './values';

html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', "Helvetica Neue", sans-serif; font-size: 14px; color: $primary-dark-color; }

input, div, textarea, button {
    font-family: 'Poppins'
}

input:focus {
    outline: none;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

table {
    width: 100%;
    border-collapse: collapse;
}

button {
    cursor: pointer;
    font-size: 14px;
    font-family: 'Poppins';
}

#loader {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -o-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    top: 50%; 
}